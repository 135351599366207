<template>
  <div>
    <i
      class="openDrawerButton el-icon-arrow-left "
      @click="drawer = true"
    ></i>
    &#160;

    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project' }">
        <app-i18n code="entities.project.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="!findLoading">{{
        this.projectID
      }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <div
        class="app-page-spinner"
        v-if="findLoading"
        v-loading="findLoading"
      ></div>
      <div style="max-width: 100%; max-height: 100%">
        <el-steps :active="active" simple finish-status="success">
          <el-step
            title="Create"
            @click.native="navigateToStep(projectStepEnum.create)"
          ></el-step>
          <el-step
            title="Release"
            @click.native="navigateToStep(projectStepEnum.release)"
          ></el-step>
          <el-step
            style="word-break: break-word"
            title="Funding"
            @click.native="navigateToStep(projectStepEnum.funding)"
          ></el-step>
          <el-step
            style="word-break: break-word"
            title="Gantt"
            @click.native="navigateToStep(projectStepEnum.gantt)"
          ></el-step>
          <el-step
            title="Implement"
            @click.native="
              navigateToStep(projectStepEnum.implementation)
            "
          ></el-step>
          <el-step
            title="Reports"
            @click.native="navigateToStep(projectStepEnum.report)"
          ></el-step>
          <el-step
            title="Complete"
            @click.native="navigateToStep(projectStepEnum.completion)"
          ></el-step>
        </el-steps>
        <router-view v-if="!findLoading"></router-view>
        <div style="position: relative;with: 100%">
          <el-drawer
            title="Notes"
            :visible.sync="drawer"
            :with-header="true"
            style="overflow: auto;"
          >
            <app-project-note
              :records="notes"
              @doCreateNote="createNote"
              :project="projectId"
            >
            </app-project-note>
          </el-drawer>
        </div>
      </div>
    </div>
    <!-- <div style="position: relative; with: 100%">
      <el-drawer
        title="Notiz"
        :visible.sync="drawer"
        :with-header="true"
        style="overflow: auto"
        v-if="id"
      >
        <app-mitarbeiter-notiz
          :records="notes"
          @doCreateNotiz="createNotiz"
          :mitarbeiter="id"
        ></app-mitarbeiter-notiz>
      </el-drawer>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ProjectForm from '@/modules/project/components/project-form';
import { routerAsync } from '@/app-module';
import Errors from '@/shared/error/errors';
import { EventBus } from '@/eventBus';
import ProjectStepEnum from '@/shared/enums/projectStep';

export default {
  name: 'app-project-form-page',

  props: ['id'],

  components: {
    [ProjectForm.name]: ProjectForm,
  },

  data() {
    return {
      active: 0,
      projectStepEnum: ProjectStepEnum.values,
      projectStepIds: ProjectStepEnum.ids,
      drawer: false,
    };
  },

  computed: {
    ...mapGetters({
      record: 'project/form/record',
      findLoading: 'project/form/findLoading',
      saveLoading: 'project/form/saveLoading',
      projectStep: 'project/form/step',
      projectId: 'project/form/projectId',
      projectID: 'project/form/projectID',
      updatedAt: 'project/form/updatedAt',
      notes: 'projectNote/note/notes',
      currentUser: 'auth/currentUser',
    }),

    isEditing() {
      return !!this.id;
    },
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
      this.navigateToStep(this.projectStep);
    } else {
      await this.doNew();
    }

    this.doFetchNotes(this.projectId);

    EventBus.$once('navigateToProjectRelease', () => {
      this.navigateToRelease();
    });

    EventBus.$once('navigateToProjectFunding', () => {
      this.navigateToProjectFunding();
    });

    EventBus.$once('navigateToProjectGantt', () => {
      this.navigateToProjectGantt();
    });

    EventBus.$once('navigateToProjectImplementation', () => {
      this.navigateToImplementation();
    });

    EventBus.$once('navigateToProjectReport', () => {
      this.navigateToReport();
    });

    EventBus.$once('navigateToProjectCompletion', () => {
      this.navigateToCompletion();
    });

    EventBus.$once('closeProject', () => {
      this.doCloseProject();
    });
  },

  beforeDestroy() {
    EventBus.$off('navigateToProjectRelease');
    EventBus.$off('navigateToProjectFunding');
    EventBus.$off('navigateToProjectGantt');
    EventBus.$off('navigateToProjectImplementation');
    EventBus.$off('navigateToProjectReport');
    EventBus.$off('closeProject');
  },

  methods: {
    ...mapActions({
      doFind: 'project/form/doFind',
      doNew: 'project/form/doNew',
      doUpdate: 'project/form/doUpdate',
      doCreate: 'project/form/doCreate',
      doClose: 'project/form/doClose',
      doCreateNote: 'projectNote/note/doCreate',
      doFetchNotes: 'projectNote/note/doFetch',
    }),

    createNote(payload) {
      try {
        this.doCreateNote(payload);
      } catch (error) {
        return;
      }
    },

    navigateToStep(step) {
      step = this.projectStepIds[step];
      const projectStepId = this.projectStepIds[this.projectStep];

      if (step > projectStepId) {
        return;
      }

      switch (step) {
        case 0:
          this.navigateToCreate();
          break;
        case 1:
          this.navigateToRelease();
          break;
        case 2:
          this.navigateToProjectFunding();
          break;
        case 3:
          this.navigateToProjectGantt();
          break;
        case 4:
          this.navigateToImplementation();
          break;
        case 5:
          this.navigateToReport();
          break;
        case 6:
          this.navigateToCompletion();
          break;
        default:
          this.navigateToCreate();
      }
    },

    navigateToCreate() {
      this.active = 0;
      this.$router.replace({
        name: 'create',
      });
    },

    navigateToRelease() {
      this.active = 1;
      this.$router.replace({
        name: 'release',
      });
    },

    navigateToProjectFunding() {
      this.active = 2;
      this.$router.replace({
        name: 'funding',
      });
    },

    navigateToProjectGantt() {
      this.active = 3;
      this.$router.replace({
        name: 'gantt',
      });
    },

    navigateToImplementation() {
      this.active = 4;
      this.$router.replace({
        name: 'implementation',
      });
    },

    navigateToReport() {
      this.active = 5;
      this.$router.replace({
        name: 'report',
      });
    },

    navigateToCompletion() {
      if (this.active != 6) {
        this.active = 6;
        this.$router.replace({
          name: 'completion',
        });
      }
    },

    doCancel() {
      routerAsync().replace('/project');
    },

    async doSubmit(payload) {
      try {
        if (this.isEditing) {
          return this.doUpdate(payload);
        } else {
          this.saveLoading = true;
          const record = await this.doCreate(payload.values);
          this.internalId = record.id;
        }
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    async doCloseProject() {
      try {
        this.saveLoading = true;
        const payload = {
          id: this.projectId,
          values: {
            updatedAt: this.updatedAt,
          },
        };

        return this.doClose(payload);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.openDrawerButton {
  float: right;
}
</style>
