<template>
  <div>
    <el-row>
      <el-col>
        <div>
          <el-steps
            :active="active"
            simple
            finish-status="success"
            style="margin-top: 20px"
            ref="elSteps"
          >
            <el-step
              title="Project Create"

              @click.native="navigateToCreate()"
            ></el-step>
            <el-step
              title="Project Release"
              @click.native="navigateToRelease()"
            ></el-step>
            <el-step
              title="Project Implementation"
              @click.native="navigateToImplementation()"
            ></el-step>
          </el-steps>
          <router-view></router-view>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ProjectCreateForm from '@/modules/project-create/components/project-create-form-project-page.vue';
import ProjectImplementation from '@/modules/project-implementation/components/project-implementation-form-project-page.vue';

import ProjectReleaseForm from '@/modules/project-release/components/project-release-form-project-page.vue';
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ProjectModel } from '@/modules/project/project-model';
import { EventBus } from '@/eventBus';

const { fields } = ProjectModel;
const formSchema = new FormSchema([
  fields.id,
  fields.projectID,
  fields.projectCreate,
  fields.projectRelease,
  fields.projectImplementation,
  fields.projectFunding,
  fields.projectReport,
  fields.status,
  fields.updatedAt,
]);

export default {
  name: 'app-project-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  components: {
    [ProjectCreateForm.name]: ProjectCreateForm,
    [ProjectReleaseForm.name]: ProjectReleaseForm,
    [ProjectImplementation.name]: ProjectImplementation,
  },

  data() {
    return {
      active: 0,
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});

    EventBus.$on('updateProject', () => {
      this.doSubmit();
    });

    EventBus.$on('updateProjectReleaseRequested', () => {
      this.doUpdateReelaseRequestStatus();
    });
  },

  
  watch: {
    record: function () {
      this.doUpdateModel();
    },
  },


  beforeDestroy() {
    EventBus.$off('updateProject');
    EventBus.$off('updateProjectReleaseRequested');
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    getStepIndex(){
      return this.$refs.elSteps.active;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    navigateToCreate() {
      this.active = 0;
      this.$router.push({
        name: 'projectCreateProject',
      });
    },

    navigateToRelease() {
      this.active = 1;
      this.$router.push({
        name: 'projectReleaseProject',
      });
    },

    navigateToImplementation() {
      this.active = 2;
      this.$router.push({
        name: 'projectImplementationProject',
      });
    },

    next() {
      if (this.active++ > 5) this.active = 0;
    },

    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doUpdateReelaseRequestStatus() {
      // Set status of the project to Release Requsted
      this.model.status =
        'CREATED/REQUESTED_FOR_RELEASE';
      this.doSubmit();
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    async doSubmitDraft() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      
      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },

    doUpdateModel() {
      this.model = formSchema.initialValues(this.record || {});
    },

    onComplete: function () {
      alert('Yay. Done!');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 480px;
}

.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>